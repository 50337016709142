/* TODO: make background color configurable */
.CbaButton {
  background: azure;
  border-style: solid;
  text-align: center;
  cursor: pointer;

  line-height: 1;
  /* RAP has default padding set from 4px up to 6px */
  padding: 0 3px;
  
  /* text will not escape button boundries*/
  white-space: nowrap;
  overflow: hidden;
}

.CbaButton-highlight-up {
  animation: CbaButton-highlight-on 1 1s linear;
  background: bisque;
}

.CbaButton-highlight-down {
  animation: CbaButton-highlight-off 1 1s linear;
  background: azure;
}

@keyframes CbaButton-highlight-on {
  from { background: azure; }
  to { background: bisque; }
}

@keyframes CbaButton-highlight-off {
  from { background: bisque; }
  to { background: azure; }
}
