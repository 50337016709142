body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  
  /* the modal window needs to use all the window space*/
  min-height: 100vh;
}

/* Necesary for the no user selection redmine issue: #5242*/
body, button > span, .suppress-selection, .suppress-selection * {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.hide-selection::selection {
  background-color: transparent;
}

.appContainer {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

/* loader */
.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 95, 172, 0.2);
  border-left: 1.1em solid rgb(0, 95, 172);
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/***************************************
Input Style - Checkbox and radio buttons 
***************************************/
.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left!important;
}

.inputWrapper input {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 13px;
  width: 13px;
  margin: auto 0;
  left: 2.5px;
}

.inputWrapper .radioWrap {
  height: 100%;
}

.inputWrapper label > span {
  margin-left: 20px;
  display: block;
  align-items: center;
  display: flex;
  height: 100%;

  /* disable text selection on RadioButton */
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.inputWrapper label .inputTextWrap {
  padding: 0 5px;
}

.inputWrapper label img {
  flex-shrink: 0;
}

/* RTL */
*[dir="rtl"] .inputWrapper {
  text-align: right!important;
}

*[dir="rtl"] .inputWrapper input {
  right: 2.5px;
  left: 0;
}

*[dir="rtl"] .inputWrapper span {
  margin-right: 20px;
  margin-left: 0;
}


/***************************************
Xpane Resizer Style
***************************************/
.Resizer {
  background: #000;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid rgba(0, 0, 0, 0.1);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.1);
  border-right: 5px solid rgba(0, 0, 0, 0.1);
}
.Resizer.disabled {
cursor: not-allowed;
}
.Resizer.disabled:hover {
border-color: transparent;
}

/* XPage - RTL */
*[dir="rtl"] .SplitPane {
  direction: ltr;
}

*[dir="rtl"] .SplitPane .Pane {
  direction: rtl;
}

/* the key pressing module add an extra div and it will not show when it focuses*/
div[tabindex="-1"]:focus {
  outline: 0;
}

/* menu z-index override */
.contextMenu .react-contextmenu.react-contextmenu--visible {
  z-index: 103;
}

/* modal windows css*/
.ui-dialog-overlay{
  background:rgb(54, 54, 54);
  opacity:0.6;
  filter:Alpha(Opacity=30);
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:100
}

.ui-dialog .ui-dialog-content,
.ui-dialog .ui-dialog-content * {
  box-sizing: border-box;
}

.ui-dialog{
  position: fixed;
  top: 50%;
  left: 50%;
  outline: 0 none;
  z-index: 101;
  background-color: #fff;
  border: 2px solid #ddd;
  border-width: 2px;
  border-style: outset;
  border-radius: 5px;
}

/* ui-dialog override */
.cba-dialog .ui-dialog{
  z-index: 97;
}

.cba-dialog .ui-dialog,
.cba-modal .ui-dialog{
  top: 0;
  left: 0;
}

.cba-dialog.dialog-center .ui-dialog,
.cba-modal.dialog-center .ui-dialog {
  top: 50%;
  left: 50%;
}

.cba-modal.cba-system-modal .ui-dialog {
  z-index: 999;
}

.cba-modal.cba-system-modal .ui-dialog-overlay {
  z-index: 900;
}

.cba-dialog .ui-dialog .ui-dialog-titlebar {
  background: #757F9A; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #757F9A, #828ba4); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #757F9A, #828ba4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.cba-dialog.focused .ui-dialog .ui-dialog-titlebar {
  background-color: rgb(0, 128, 192);
  background: linear-gradient(to bottom, rgb(0, 95, 172),rgb(0, 80, 146));
}

.cba-modal.not-closable .ui-dialog > header, .cba-dialog.not-closable .ui-dialog > header {
  display:none;
}

.cba-modal.not-closable .ui-dialog, .cba-dialog.not-closable .ui-dialog {
  border: 0;
  border-radius: 0;
  border-style: none;
  background: transparent;
}

.cba-dialog.focused .ui-dialog {
  z-index: 98;
}

.ui-dialog.maximized{
  position:fixed;
  top:0;
  left:0;
  width:100%!important;
  height:100%!important
}

.ui-dialog.minimized{
  position:fixed;
  bottom:0;
  right:0
}

.ui-dialog .ui-dialog-titlebar{
  position: relative;
  color: #fff;
  padding: 4px;
  border-bottom: 1px solid #f6f6f6;
  font-size: 12px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: rgb(0, 128, 192);
  background: linear-gradient(to bottom, rgb(0, 95, 172),rgb(0, 80, 146));
  min-height: 16px;
}

.ui-dialog.react-draggable .ui-dialog-titlebar{
  cursor:move
}

.ui-dialog .ui-dialog-titlebar .action-items{
  position: absolute;
  right: 4px;
  top: 4px;

}

.ui-dialog .ui-dialog-titlebar .title {
  margin-right:1px;
  font-family:Arial,Helvetica,sans-serif;
  font-size:12px;
}

.icon {
  display:block;
  cursor: pointer;
  font-style: normal;
  text-align: center;
}

.icon.icon-close::after {
  content: '\d7';
  display: block;
  width: 14px;
  border: 1px solid white;
  position: relative;
  top: -1px;
  right: -1px;
  border-radius: 50%;
}

.ui-dialog .action-items .icon-close:hover:after {
  background-color: #FF512F;
  background: linear-gradient(to bottom, #F09819, #FF512F);
}

.ui-dialog .ui-dialog-content{
  background:none repeat scroll 0 0 transparent;
  border:0 none;
  overflow:auto;
  position:relative;
  padding:.5em;
  width: 100%;
  height: calc(100% - 25px);
  position: relative;
}

.ui-dialog .ui-dialog-content .dialog-page {
  width: 100%;
  height: 100%;
  position: relative;
  left: -0.5em;
  top: -0.5em;
}

.ui-dialog .ui-dialog-content .dialog-page > div{
  top: 0!important;
  left: 0!important;
}

.ui-dialog .ui-dialog-buttonpane{
  position:absolute;
  width:100%;
  bottom:0;
  text-align:right;
  border-width:1px 0 0;
  border-top:1px solid #f6f6f6
}
.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset{
  padding:.5em
}
.ui-dialog .ui-dialog-buttonpane button{
  margin:0 .5em;
  cursor:pointer;
  background-color:#f6f6f6;
  padding:.5em 1em;
  outline:none;
  border:1px solid #ccc;
  border-radius:3px
}
.ui-dialog .ui-dialog-buttonpane button:hover{
  background-color:#ccc;
  border:1px solid #bbb
}
.ui-dialog .react-resizable-handle{
  position:absolute;
  width:20px;
  height:20px;
  bottom:0;
  right:0;
  padding:0 3px 3px 0;
  background-origin:content-box;
  box-sizing:border-box;
  cursor:se-resize
}

/* modal windows content */
.cba-modal-content {
  font-family: "Segoe UI", Geneva, sans-serif;
  font-size: 9px;
  text-align: left;
}

.cba-modal-content table{
  width: 100%;
  background-color: white;
}

.cba-modal-content .summary{
  background-color: rgb(250, 250, 250);
}

.cba-modal-content .details{
  border: 1px solid rgb(114, 114, 114);
}

.cba-modal-content .details tr:nth-child(even){
  background-color: #f2f2f2;
}

.cba-modal-content .details th {
  background-color: rgb(160, 160, 160);
  color: white;
}
.cba-modal-content .detailsWrapper{
  height: 380px;
}

.cba-modal-content .cba-trace-modal-buttons {
  background-color: #fff;
  position: fixed;
  bottom: 17px;
  left: 7px;
}

/* Login and forms */

.login-container {
  position: relative;
  top: 200px;
  width: 400px;
  border: 1px solid #337ab7;
  margin: 0 auto;
  border-radius: 3px;
}

.login-head {
  background-color: #337ab7;
  padding: 10px 15px;
  color: #fff;
}

.login-head h3 {
  margin: 0;
}

.login-body {
  padding: 10px 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group > input {
  display: block;
  width: 100%;
  padding: 10px 6px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-sizing: border-box;
}

.form-group > label {
  display: inline-block;
  margin-bottom: 10px;
}

.login-body .btn-container {
  text-align: center;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
}

.btn.blue-button {
  background-color: #337ab7;
  color: #fff;
}


/*header buttons */

.headerButtonsToolbar {
  background-color: #e7e7e7;
  margin-bottom: 5px;
}

.headerButtonsToolbar::after {
  display: block;
  clear: both;
  content: "";
}



@media only screen and (max-width: 700px) {
  .login-container {
    top: 15px;
    width: calc(100% - 30px);
    margin: 0 auto;
  }
  
}


/*cols definitions*/
.cbaColWidth05 {
  width: 5%;
}

.cbaColWidth15 {
  width: 15%;
}

.cbaColWidth25 {
  width: 25%;
}

.cbaColWidth30 {
  width: 30%;
}

.cbaColWidth35 {
  width: 35%;
}

.cbaColWidth85 {
  width: 85%;
}


.centerVerticalHorizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pause-backdrop {
  position: fixed;
  background: rgba(112, 112, 112, 0.5);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 500;
}

.pause-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-sizing: border-box;
  height: 96px;
  border-color: transparent transparent transparent #f0f0f0;
  border-style: double;
  border-width: 0px 0 0px 78px;
}

.display-none {
  display: none;
}