/* *
* Rangeslider
*/
.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
}
.rangeslider.disabled { 
  opacity: 0.5;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}
.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}
.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
}
/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 5px;
  margin: 20px 5px;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  border-radius: 10px;
  top: 0;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(229, 229, 229)), to(rgb(229, 229, 229))), rgb(229, 229, 229);
}
.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 21px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(229, 229, 229)), to(rgb(229, 229, 229))), rgb(229, 229, 229);
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider-horizontal .rangeslider__handle:after {
  content: ' ';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
  display: none
}
/**
* Rangeslider - Vertical slider
*/
.rangeslider-vertical {
  margin: 5px 0 5px 20px;
  height: calc(100% - 10px);
  max-width: 5px;
}
.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  box-shadow: none;
  bottom: 0;
}
.rangeslider-vertical .rangeslider__handle {
  width: 21px;
  height: 10px;
  left: -10px;
  box-shadow: none;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(229, 229, 229)), to(rgb(229, 229, 229))), rgb(229, 229, 229);
}
.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}

.rangeslider ul.rangeslider__labels {
  display: none;
}

