.draftJsLinkifyPlugin__link__2ittM, .draftJsLinkifyPlugin__link__2ittM:visited {
  color: #5e93c5;
  text-decoration: none;
}

.draftJsLinkifyPlugin__link__2ittM:hover, .draftJsLinkifyPlugin__link__2ittM:focus {
  color: #7eadda;
  outline: 0; /* reset for :focus */
  cursor: pointer;
}

.draftJsLinkifyPlugin__link__2ittM:active {
  color: #4a7bab;
}

.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block;
}

.draftJsToolbar__button__qi1gf {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.draftJsToolbar__button__qi1gf svg {
  fill: #888;
}

.draftJsToolbar__button__qi1gf:hover, .draftJsToolbar__button__qi1gf:focus {
  background: #f3f3f3;
  outline: 0; /* reset for :focus */
}

.draftJsToolbar__active__3qcpF {
  background: #efefef;
  color: #444;
}

.draftJsToolbar__active__3qcpF svg {
  fill: #444;
}
.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em;
}

.draftJsToolbar__toolbar__dNtBH {
  left: 50%;
  -webkit-transform: translate(-50%) scale(0);
          transform: translate(-50%) scale(0);
  position: absolute;
  z-index: 2;
}




