
/* CBA overrides */

span.cba-link span {
	color: inherit!important;
}

/* CBA block style classes */

.cbaAlignLeft > div {
  text-align: left!important;
}

.cbaAlignCenter > div {
  text-align: center!important;
}

.cbaAlignRight > div {
  text-align: right!important;
}

.cbaAlignJustify > div {
  text-align: justify!important;
  text-align-last: left;
  -moz-text-align-last: left;
  white-space: normal;
}

*[dir="rtl"] .cbaAlignJustify > div {
  text-align-last: right;
  -moz-text-align-last: right;
}

/****************************************
***************List Styles***************
*****************************************/

/* List item container */

.cbaListItemLeft,
.cbaListItemRight {
  position: relative;
}

.cbaListItemLeft > div,
.cbaListItemRight > div {
  display: inline-block;
}

.cbaListItemLeft > div {
  margin-left: 20px;
}

.cbaListItemRight > div {
  margin-right: 20px;
}

.cbaListItemLeft.cbaAlignCenter,
.cbaListItemRight.cbaAlignCenter {
  text-align: center;
}

.cbaListItemLeft.cbaAlignJustify,
.cbaListItemRight.cbaAlignJustify {
  text-align: justify;
}

.cbaListItemLeft.cbaAlignLeft,
.cbaListItemRight.cbaAlignLeft {
  text-align: left;
}

.cbaListItemLeft.cbaAlignRight,
.cbaListItemRight.cbaAlignRight {
  text-align: right;
}

/* Bullet */

.cbaListItemLeft .cbaListBullet,
.cbaListItemRight .cbaListBullet {
  position:absolute;
  width: 20px;
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
}

.cbaListItemRight .cbaListBullet {
  right: -20px;
}

.cbaListItemLeft .cbaListBullet {
  left: -20px;
}

/*numbered bullet bigger font-size overwrites*/
/* cbaListSize1 - 14px -> 25px */
.cbaListItemLeft.cbaListSize1 > div {
  margin-left: 40px;
}
.cbaListItemLeft.cbaListSize1 .cbaListBullet {
  left: -40px;
  width: 40px;
}
.cbaListItemRight.cbaListSize1 > div {
  margin-right: 40px;
}
.cbaListItemRight.cbaListSize1 .cbaListBullet {
  right: -40px;
  width: 40px;
}

/* cbaListSize2 - 26px -> 40px */
.cbaListItemLeft.cbaListSize2 > div {
  margin-left: 60px;
}
.cbaListItemLeft.cbaListSize2 .cbaListBullet {
  left: -60px;
  width: 60px;
}
.cbaListItemRight.cbaListSize2 > div {
  margin-right: 60px;
}
.cbaListItemRight.cbaListSize2 .cbaListBullet {
  right: -60px;
  width: 60px;
}

/* cbaListSize3 - 41px -> 55px */
.cbaListItemLeft.cbaListSize3 > div {
  margin-left: 80px;
}
.cbaListItemLeft.cbaListSize3 .cbaListBullet {
  left: -80px;
  width: 80px;
}
.cbaListItemRight.cbaListSize3 > div {
  margin-right: 80px;
}
.cbaListItemRight.cbaListSize3 .cbaListBullet {
  right: -80px;
  width: 80px;
}






/* image */
.cbaImageLeft {
  float: left;
  margin: 2px
}

.cbaImageRight {
  float: right;
  margin: 2px
}

.cbaClearImage {
  clear: both;
  height: 0;
  font-size: 0;
  visibility: hidden;
  width: 0;
}

/* Toolbar plugin */

.toolbarInner {
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220,220,220,1);
  z-index: 2;
  box-sizing: border-box;
}

.toolbarInner:after, .toolbarInner:before {
  top: -12px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.toolbarInner:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 4px;
  margin-left: -4px;
  top: -8px;
}

.toolbarInner:before {
  border-color: rgba(221, 221, 221, 0);
  border-bottom-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}

.toolbarInner span {
  padding: 5px;
  display: block;
  background: #fbfbfb;
  cursor: pointer;
  font-size: 15px;
}
